<template>
  <div class="dizme_tm_section" id="blog">
    <div class="dizme_tm_news">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>From My Blog</span>
          <h3>Our Recent Updates, Blog, Tips, Tricks &amp; More</h3>
        </div>
        <div class="news_list">
          <ul>
            <li class="wow fadeInUp" data-wow-duration="1s">
              <div class="list_inner">
                <div class="image">
                  <img src="/img/thumbs/42-29.jpg" alt="" />
                  <div class="main" data-img-url="/img/news/1.jpg"></div>
                  <div class="date">
                    <h3>23</h3>
                    <span>Dec</span>
                  </div>
                  <a
                    class="dizme_tm_full_link"
                    href="#"
                    @click.prevent="active = 1"
                  ></a>
                </div>
                <div class="details">
                  <span class="category"
                    ><a href="#" @click.prevent="active = 1"
                      >Web Development</a
                    ></span
                  >
                  <h3 class="title">
                    <a href="#" @click.prevent="active = 1"
                      >Jim Morisson Says when the musics over turn off the
                      light</a
                    >
                  </h3>
                </div>
              </div>
            </li>
            <li
              class="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div class="list_inner">
                <div class="image">
                  <img src="/img/thumbs/42-29.jpg" alt="" />
                  <div class="main" data-img-url="/img/news/2.jpg"></div>
                  <div class="date">
                    <h3>23</h3>
                    <span>Dec</span>
                  </div>
                  <a
                    class="dizme_tm_full_link"
                    href="#"
                    @click.prevent="active = 2"
                  ></a>
                </div>
                <div class="details">
                  <span class="category"
                    ><a href="#" @click.prevent="active = 2">Branding</a></span
                  >
                  <h3 class="title">
                    <a href="#" @click.prevent="active = 2"
                      >How to be appreciated for your hard work as a
                      developer</a
                    >
                  </h3>
                </div>
              </div>
            </li>
            <li
              class="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
            >
              <div class="list_inner">
                <div class="image">
                  <img src="/img/thumbs/42-29.jpg" alt="" />
                  <div class="main" data-img-url="/img/news/3.jpg"></div>
                  <div class="date">
                    <h3>23</h3>
                    <span>Dec</span>
                  </div>
                  <a
                    class="dizme_tm_full_link"
                    href="#"
                    @click.prevent="active = 3"
                  ></a>
                </div>
                <div class="details">
                  <span class="category"
                    ><a href="#" @click.prevent="active = 3"
                      >Social Media</a
                    ></span
                  >
                  <h3 class="title">
                    <a href="#" @click.prevent="active = 3"
                      >How designers and developers can collaborate better</a
                    >
                  </h3>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="brush_1 wow zoomIn" data-wow-duration="1s">
        <img src="/img/brushes/news/1.png" alt="" />
      </div>
      <div class="brush_2 wow zoomIn" data-wow-duration="1s">
        <img src="/img/brushes/news/2.png" alt="" />
      </div>
    </div>
  </div>
  <div :class="active == 1 ? '' : 'news_hidden_details'">
    <ModalBox :close="close">
      <div class="news_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/news/1.jpg"
            style="background-image: url('img/news/1.jpg')"
          ></div>
        </div>
        <div class="details">
          <h2>Navigating the Color Palette: A Guide to Effective UI Design</h2>
          <span><a href="#">Color Palette</a></span>
          <div></div>
        </div>
        <div class="text">
          <p>
            In the world of UI design, colors aren't just about aesthetics; they're a powerful tool that can influence user
             behavior, evoke emotions, and enhance overall user experience. Navigating the color palette is like being a painter,
              strategically choosing each hue to create a masterpiece that resonates with users. In this guide, we'll explore the
               art and science behind effective color choices in UI design.
          </p>
          <h5>Understanding the Psychology of Colors</h5>
          <p>
           Before delving into the practical aspects of choosing colors, it's crucial to understand the psychology behind them.
            Colors have the ability to convey emotions and elicit specific responses. For example, warm tones like red and orange
             can evoke energy and excitement, while cool tones such as blue and green may create a sense of calm and trust.
              Take the time to understand the emotions you want your users to associate with your interface.
          </p>
          <h5>Creating a Consistent Color Scheme</h5>
          <p>
            Consistency is key in UI design, and the color palette is no exception. Establish a cohesive color scheme that 
            aligns with your brand identity and conveys a unified message. Whether you're using a monochromatic, analogous, 
            or complementary scheme, consistency in color application enhances visual harmony and helps users navigate your 
            interface seamlessly.
          </p>
          <h5>Prioritizing Readability and Accessibility</h5>
          <p>Colors play a pivotal role in ensuring readability and accessibility. High contrast between text and background colors
           enhances legibility, especially for users with visual impairments. Adhering to accessible color contrast ratios not only 
           makes your design more inclusive but also ensures that information is easily readable for all users.</p>
          <h5>The Power of Accent Colors</h5>
          <p>While a consistent color scheme forms the backbone of your UI design, accent colors are the sparks that draw attention
           to specific elements. Strategic use of accent colors can guide users through your interface, highlight important 
           calls-to-action, and create a visually dynamic experience. Just like a well-placed highlight in a painting, accent 
           colors draw the eye to key focal points.</p>
          <h5>Considering Cultural Associations</h5>
          <p>Colors can carry cultural significance, and what resonates positively in one culture may have a different 
          connotation in another. Be mindful of the cultural diversity of your audience, especially if your product or website 
          has a global reach. Conducting research on color meanings in various cultures can prevent unintentional misinterpretations.</p>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="active == 2 ? '' : 'news_hidden_details'">
    <ModalBox :close="close">
      <div class="news_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/news/2.jpg"
            style="background-image: url('img/news/2.jpg')"
          ></div>
        </div>
        <div class="details">
          <h3>How to be appreciated for your hard work as a developer</h3>
          <span><a href="#">Branding</a></span>
          <div></div>
        </div>
        <div class="text">
          <p>
            Dizme is a leading web design agency with an award-winning design
            team that creates innovative, effective websites that capture your
            brand, improve your conversion rates, and maximize your revenue to
            help grow your business and achieve your goals.
          </p>
          <p>
            In today’s digital world, your website is the first interaction
            consumers have with your business. That's why almost 95 percent of a
            user’s first impression relates to web design. It’s also why web
            design services can have an immense impact on your company’s bottom
            line.
          </p>
          <p>
            That’s why more companies are not only reevaluating their website’s
            design but also partnering with Kura, the web design agency that’s
            driven more than $2.4 billion in revenue for its clients. With over
            50 web design awards under our belt, we're confident we can design a
            custom website that drives sales for your unique business.
          </p>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="active == 3 ? '' : 'news_hidden_details'">
    <ModalBox :close="close">
      <div class="news_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/news/3.jpg"
            style="background-image: url('img/news/3.jpg')"
          ></div>
        </div>
        <div class="details">
          <h3>How designers and developers can collaborate better</h3>
          <span><a href="#">Social Media</a></span>
          <div></div>
        </div>
        <div class="text">
          <p>
            Dizme is a leading web design agency with an award-winning design
            team that creates innovative, effective websites that capture your
            brand, improve your conversion rates, and maximize your revenue to
            help grow your business and achieve your goals.
          </p>
          <p>
            In today’s digital world, your website is the first interaction
            consumers have with your business. That's why almost 95 percent of a
            user’s first impression relates to web design. It’s also why web
            design services can have an immense impact on your company’s bottom
            line.
          </p>
          <p>
            That’s why more companies are not only reevaluating their website’s
            design but also partnering with Kura, the web design agency that’s
            driven more than $2.4 billion in revenue for its clients. With over
            50 web design awards under our belt, we're confident we can design a
            custom website that drives sales for your unique business.
          </p>
        </div>
      </div>
    </ModalBox>
  </div>
</template>

<script>
import ModalBox from "./popup/ModalBox.vue";
export default {
  name: "NewsComponent",
  components: { ModalBox },
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    close() {
      this.active = 0;
    },
  },
};
</script>
